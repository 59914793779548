<template>
  <div class="page">
    <BreadCrumb />
    <el-button class="goBack" plain round size="mini" type="primary" @click="goBack"> 返回 </el-button>
    <div class="page-body">
      <el-descriptions title="基础配置信息"   :column="4"  border  >
          <el-descriptions-item label="作业ID"> {{ workForm.workId }}</el-descriptions-item>
          <el-descriptions-item label="作业名称"> {{ workForm.workName }}</el-descriptions-item>
          <el-descriptions-item label="创建人"> {{ workForm.createBy }}</el-descriptions-item>
          <el-descriptions-item label="触发方式">{{ workForm.triggerMethod == 1001 ? '手动(MANUAL)' : '自动(AUTO)' }}</el-descriptions-item>
          <el-descriptions-item label="调度周期" v-if="workForm.workCron"> {{ workForm.workCron }}</el-descriptions-item>
          <el-descriptions-item label="生效开始时间" v-if="workForm.startTime"> {{ workForm.startTime }}</el-descriptions-item>
          <el-descriptions-item label="生效结束时间" v-if="workForm.endTime"> {{ workForm.endTime }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">  {{ workForm.createTime }}</el-descriptions-item>
          <el-descriptions-item label="更新时间"> {{ workForm.updateTime }}</el-descriptions-item>
      </el-descriptions>
       <el-descriptions title="输入配置信息"   :column="3"  border  class="m-t-20">
         <el-descriptions-item label="稽核方式" :span="3"> {{ workForm.checkMode == 1010 ? '全量(FULL)' : '增量(INCR)' }}</el-descriptions-item>
         <div v-for="(item, index) in workInputMetaResponses" :key="index">
           <el-descriptions-item label="库"> {{ item.dsId.dsDesc==""?item.dsId.dsName:item.dsId.dsDesc}}</el-descriptions-item>
           <el-descriptions-item label="表"> {{ item.tabId.tabDesc==""?item.tabId.tabName:item.tabId.tabDesc}}</el-descriptions-item>
           <el-descriptions-item label="列"> 
              <div v-for="(list, idx1) in item.workInputMetaOutputColResponses" :key="idx1" class="flex1">
                {{ list.colId.colDesc==""?list.colId.colName:list.colId.colDesc}}
              </div>
           </el-descriptions-item>
         </div>
      </el-descriptions>
      <el-descriptions title="表关系"   :column="7"  border  class="m-t-20">
         <div v-for="(item, index) in workInputMetaRelationResponses" :key="index">
           <el-descriptions-item label="库"> {{ item.leftDsId.dsDesc==""?item.leftDsId.dsName:item.leftDsId.dsDesc}}</el-descriptions-item>
           <el-descriptions-item label="表"> {{ item.leftTabId.tabDesc==""?item.leftTabId.tabName:item.leftTabId.tabDesc}}</el-descriptions-item>
           <el-descriptions-item label="列"> {{ item.leftCol.colDesc==""?item.leftCol.colName:item.leftCol.colDesc}}</el-descriptions-item>
           <el-descriptions-item label="连接条件"> {{ item.joinCond}}</el-descriptions-item>
           <el-descriptions-item label="列"> {{ item.rightCol.colDesc==""?item.rightCol.colName:item.rightCol.colDesc}}</el-descriptions-item>
           <el-descriptions-item label="表"> {{ item.rightTabId.tabDesc==""?item.rightTabId.tabName:item.rightTabId.tabDesc}}</el-descriptions-item>
           <el-descriptions-item label="库"> {{ item.rightDsId.dsDesc==""?item.rightDsId.dsName:item.rightDsId.dsDesc}}</el-descriptions-item>
         </div>
      </el-descriptions>
      <el-descriptions title="输出配置信息"   :column="2"  border  class="m-t-20">
        <el-descriptions-item label="入库方式"> {{ workForm.writeMode == 3001 ? '覆盖写(Overwrite)' : '追加写(Append)' }}</el-descriptions-item>
        <el-descriptions-item label="目标库" > 
          <div v-for="(item, index) in workOutputMetaResponses" :key="index">
            {{ item.dsId.dsDesc==""?item.dsId.dsName:item.dsId.dsDesc }}
          </div>
        </el-descriptions-item>
      </el-descriptions>
      
      <el-descriptions title="规则信息"   :column="3"  border  class="m-t-20">
        <div  v-for="(item, index) in ruleResponses" :key="index">
          <el-descriptions-item label="模板主键"> {{ item.templateId.templateId }}</el-descriptions-item>
          <el-descriptions-item label="关联规则"> {{ item.templateId.templateName }}</el-descriptions-item>
          <el-descriptions-item label="创建时间"> {{ item.templateId.createTime }}</el-descriptions-item>
        </div>
      </el-descriptions>

    </div>
  </div>
</template>
<script>
  /* eslint-disable */
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
export default {
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
    return {
      projectId: '', //返回用
      workId: '',
      loading: true,
      // table数据
      workForm: '', //当前页的 数据
      workInputMetaResponses:"", //输入配置信息 库、表、列
      workInputMetaRelationResponses:"",//表关系
      workOutputMetaResponses:"",//目标库
      ruleResponses: '', //绑定规则信息
      pageP1: '',
      pageP2: '',
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
    var _this = this
    console.log(_this.$route)
    _this.pageP1 = Number(this.$route.query.pageP1)
    _this.pageP2 = Number(this.$route.query.pageP2)
    _this.workId = Number(_this.$route.query.workId)
    _this.projectId = Number(_this.$route.query.projectId)
    _this.workAll(_this.workId)
  },
  methods: {
    //作业任务列表
    workAll(workId) {
      var _this = this
      console.log('作业任务列表')
      this.$axios.GET('/api/v1/core/project/work/' + workId).then(res => {
        _this.loading = false;
        let data = res.data;
        console.log(data)
        _this.workForm = data;
         console.log(_this.workForm)
        switch(_this.workForm.workCron) {
             case 2001:
                _this.workForm.workCron = "5分钟调度一次";
            break;
             case 2002:
                _this.workForm.workCron = "10分钟调度一次";
            break;
            case 2003:
              _this.workForm.workCron = "15分钟调度一次";
               break;
            case 2004:
             _this.workForm.workCron = "20钟调度一次";
            case 2005:
             _this.workForm.workCron = "30分钟调度一次";
            case 2006:
             _this.workForm.workCron = "1小时调度一次";
            case 2007:
             _this.workForm.workCron = "6小时调度一次";
             break;
        } 
        
         _this.workInputMetaResponses= data.workInputMetaResponses;
         _this.workInputMetaRelationResponses= data.workInputMetaRelationResponses;
         _this.workOutputMetaResponses= data.workOutputMetaResponses;
         for(let item of _this.workInputMetaRelationResponses){
           switch(item.joinCond) {
                case 1034:
                   item.joinCond = "全外连接"
                   break;
                case 1031:
                   item.joinCond = "内连接"
                   break;
               case 1033:
                  item.joinCond = "右连接"
                  break;
               case 1032:
                item.joinCond = "左连接"
                break;
           } 
         }
         
        _this.ruleResponses = data.ruleResponses;
      })
    },
    //返回上一级
    goBack() {
      console.log('返回上一级')
      //带参数跳转
      this.$router.push({
        name: 'projectDetails',
        query: { projectId: this.projectId, pageP1: this.pageP1, pageP2: this.pageP2 },
      })
    },
  },
}
</script>

<style scoped>
.workDetails {
  height: auto;
}

.workDetails .el-col {
  min-height: 50px;
}

.workDetails .el-col > div > span {
  margin-right: 15px;
}

.workDetails .el-col > div > div {
  color: #000;
  font-weight: 700;
}
</style>
